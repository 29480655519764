body {
  background-color: #f8f9fa;
}
.btn {
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 0;
}

.alert {
  border-radius: 0;
}

.modal-header {
  .close {
    color: #fff;
  }
}

.modal-content {
  border-color: transparent;
  background-color: #fff;
  border-radius: 0;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
  border: 0;
  .modal-header {
    border-radius: 0;
  }
}

.better-backdrop {
  backdrop-filter: blur(10px);
  background-color: rgba(100, 100, 100, 0.3);
  transition: all 0.15s linear;
  &.show {
    opacity: 1;
  }
}
