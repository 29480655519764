html {
  font-size: $html_font_size;
}

body {
  font-family: $font_body;
  line-height: $body_line_height;
  color: $color_text_2;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $color_brand;
  transition: color 0.1s ease-in-out;

  &:hover,
  &.hover {
    color: darken($color_brand, 20%);
    text-decoration: none;
  }
  &:active {
    color: $color_brand;
  }
}

p {
  margin-top: -5px;
  margin-bottom: 1.8rem;
}

b,
strong {
  font-weight: 600;
}

small {
  font-size: 85%;
}

label {
  margin-bottom: 0.7rem;
  font-weight: 400;
  color: $color_secondary;
}

pre {
  display: flex;
  flex-wrap: wrap;
}

code:not(.hljs) {
  display: inline-block;
  padding: 3px 5px;
  color: $color_secondary;
  background-color: $color_grey_1;
  border-radius: 0.2rem;
}

mark,
.mark {
  padding: 3px 6px;
  border-radius: 0.2rem;
}

/* headings */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 1.7rem;
  font-family: $font_title;
  font-weight: 400;
  line-height: $heading_line_height;
  color: $color_text_1;
  text-transform: none;
  letter-spacing: normal;
}
h1,
.h1 {
  margin-top: -5px;
  font-size: 1.846rem;
}
h2,
.h2 {
  margin-top: -4px;
  font-size: 1.538rem;
}
h3,
.h3 {
  margin-top: -3px;
  font-size: 1.385rem;
}
h4,
.h4 {
  margin-top: -6px;
  font-size: 1.231rem;
}
h5,
.h5 {
  margin-top: -2px;
  font-size: 1.077rem;
}
h6,
.h6 {
  margin-top: -2px;
  font-size: 0.923rem;
}
.display-1,
.display-2,
.display-3,
.display-4 {
  font-weight: 300;
  text-transform: none;
  letter-spacing: normal;
}
.display-1 {
  margin-top: -11px;
  margin-bottom: 1.15rem;
  font-size: 3.846rem;
}
.display-2 {
  margin-top: -9px;
  margin-bottom: 1.25rem;
  font-size: 3.385rem;
}
.display-3 {
  margin-top: -7px;
  margin-bottom: 1.45rem;
  font-size: 2.769rem;
}
.display-4 {
  margin-top: -5px;
  margin-bottom: 1.55rem;
  font-size: 2.154rem;
}

/* Lead */
.lead {
  margin-top: -7px;
  margin-bottom: 1.65rem;
  font-size: 1.385rem;
  font-weight: 300;
}
