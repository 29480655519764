.PhoneInput {
  display: flex !important;
  .PhoneInputInput {
    display: flex !important;
    border: none;
    background: transparent;
    color: #495057;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    &:focus,
    &.focus {
      border-color: rgba($color_brand, 0.6);
      outline: 0;
    }
  }
}
