.rui-router-transition {
  &-enter > div {
    opacity: 0;
  }

  &-enter-active > div {
    opacity: 1;
    transition: opacity 300ms;
  }

  &-exit > div {
    opacity: 1;
  }

  &-exit-active > div {
    opacity: 0;
    transition: opacity 300ms;
  }
}
